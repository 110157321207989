import Vue from 'vue'
// import axios from 'axios';
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  //admin
  {
    path: '/admin',
    name: 'Admin',
    component: () => import( /* webpackChunkName: "admin_dashboard" */ '../admin_dashboard/Dashboard.vue'),
    meta: {
      requiresAdmin: true
    },

    children: [{
        path: 'databases',
        component: () => import( /* webpackChunkName: "databases" */ '../admin_dashboard/views/Databases.vue'),
      },

      {
        path: 'users',
        component: () => import( /* webpackChunkName: "users" */ '../admin_dashboard/views/Users.vue'),
      },

      {
        path: 'subscriptions',
        component: () => import( /* webpackChunkName: "subscription" */ '../admin_dashboard/views/Subscriptions.vue'),

      },
    ]
  },

  //auth
  {
    path: '/auth',
    name: 'Auth',
    redirect: '/auth/signin',
    component: () => import( /* webpackChunkName: "login" */ '../views/Auth/Entry.vue'),
    meta: {
      guest: true
    },

    children: [{
        path: 'admin',
        name: 'Admin',
        component: () => import( /* webpackChunkName: "admin" */ '../admin_dashboard/Login.vue'),
      },

      {
        path: 'signin',
        name: 'Login',
        component: () => import( /* webpackChunkName: "login" */ '../views/Auth/Login.vue'),
      },

      {
        path: 'signup',
        name: 'Signup',
        component: () => import( /* webpackChunkName: "signup" */ '../views/Auth/Signup.vue'),
      },

      {
        path: 'verify',
        name: 'Verify',
        component: () => import( /* webpackChunkName: "verify" */ '../views/Auth/VerifyEmail.vue'),
        meta: {
          requiresVerify: true
        },
      }
    ]
  },

  {
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "site" */ '../views/Site/Home.vue'),
    meta: {
      requiresAuth: false
    },

  }, //if logged in = redirect to platform, otherwise to site! todo**

  //platform
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import( /* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    },
    
  },
  {
    path: '/database',
    name: 'Databases',
    component: () => import( /* webpackChunkName: "databases" */ '../views/Databases.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import( /* webpackChunkName: "search" */ '../views/Search.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import( /* webpackChunkName: "support" */ '../views/Support.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: () => import( /* webpackChunkName: "purchase" */ '../views/Subscribe.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import( /* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/settings',
    name: 'Account Settings',
    component: () => import( /* webpackChunkName: "account" */ '../views/Settings.vue'),
    meta: {
      requiresAuth: true
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  var token = localStorage.token;

  var auth = false;

  if (token) {
    var user = Vue.prototype.$jwt(token);

    if (!user) auth = false;

    auth = true;

    if (Math.floor(Date.now() / 1000) > user.exp) {
      auth = false;
      localStorage.clear();
    }
  }

  if (to.fullPath == '/auth/verify') {
    try {
      var session = JSON.parse(localStorage.session)
      if (!session || !session.username || !session.toverify) {
        next({
          path: '/auth',
          query: to.query
        })
        return
      }
    } catch (err) {
      next({
        path: '/auth',
        query: to.query
      })
      return
    }
  }

  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (!auth) {
      next({
        path: '/auth',
        query: to.query
      })
    }

    let isAdmin = user.is_admin == 0 ? false : true;

    if (isAdmin) {
      next();
    } else {
      next({
        path: '/auth',
        query: to.query
      })
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (auth) {
      next()

    } else {
      next({
        path: '/auth',
        query: to.query
      })
    }

  } else if (to.matched.some(record => record.meta.guest)) {
    if (!auth) {
      next()
    } else {
      window.location.href = '../dashboard'

    }
  } else {
    next()
  }
})


export default router