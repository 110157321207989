import axios from 'axios';
Vue.prototype.$http = axios.create({
  baseURL: 'https://api.celestine.pw'
})

import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import 'dtoaster/dist/dtoaster.css'
import DToaster from 'dtoaster'
import ToasterPresets from './assets/json/toast_presets.json'

Vue.prototype.$jwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

Vue.use(DToaster, {
  presets: ToasterPresets,
  position: 'top-right',
  containerOffset: '45px',
})

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')