import Vue from 'vue';
import { theme } from '../assets/themes'
import Vuetify from 'vuetify/lib/framework';
Vue.use(Vuetify);
export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: theme.themes
    },
});
