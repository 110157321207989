<template>
  <v-app>
    <div v-if="isPlatform">
      <Navigation
        :menu_items="menu_items"
        :appTitle="appTitle"
        :logo="logo"
        :appDescription="appDescription"
      />
      <v-main>
        <router-view />
      </v-main>
    </div>

    <div v-else>
      <router-view />
    </div>
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import vue from "vue";

export default {
  name: "App",

  components: {
    Navigation,
  },

  created() {
    if (localStorage.token)
      this.$http.defaults.headers.common["Authorization"] = localStorage.token;
  },

  mounted() {
    this.$vuetify.theme.dark = true;
    if (!localStorage.token) return;
    if (
      !window.location.pathname.includes("admin") &&
      !window.location.pathname.includes("auth") &&
      window.location.pathname != "/"
    )
      this.isPlatform = true;

    const user = this.$jwt(localStorage.token);
    if (!user) this.$router.push("/");

    vue.prototype.$user = user;

    const planIndex = this.menu_items.findIndex((e) => e.path == "/subscribe");
    this.menu_items[planIndex].status.icon = !user.plan
      ? "mdi-close-circle-outline"
      : "mdi-check-circle-outline";
    this.menu_items[planIndex].status.color = !user.plan
      ? "#E74C3C"
      : "#2ecc71";
  },

  data() {
    return {
      isPlatform: false,

      appTitle: "Celestine",
      appDescription: "Workspace",
      logo: { icon: "mdi-water-circle", color: "#286aff" },
      menu_items: [
        {
          head: true,
          icon: "mdi-ticket-percent-outline",
          text: "Subscription",
          path: "/subscribe",

          status: {
            icon: "mdi-close-circle-outline",
            color: "#E74C3C",
          },
        },

        {
          head: true,
          icon: "mdi-face-agent",
          text: "Support",
          path: "/support",
        },
        {
          head: false,
          icon: "mdi-home-variant-outline",
          text: "Dashboard",
          path: "/dashboard",
        },
        {
          head: false,
          icon: "mdi-database-search-outline",
          text: "Search",
          path: "/search",
        },
        {
          head: false,
          icon: "mdi-database-outline",
          text: "Databases",
          path: "/database",
        },
      ],
    };
  },
};
</script>


<style lang="scss">
@import url("./assets/style/__global.scss");

.v-application {
  background: var(--v-bg-base) !important;
}

body,
html {
  overflow-x: hidden;
  background: var(--v-bg-base);

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0);
    border-radius: 15px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(12, 12, 12);
  }
}
</style>
