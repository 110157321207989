<template>
  <div id="navigation">
    <v-navigation-drawer
      v-model="drawer"
      :permanent="$vuetify.breakpoint.mdAndUp"
      width="300px"
      app
    >
      <!-- Title -->
      <v-list-item>
        <v-list-item-content class="text-center">
          <v-list-item-title class="d-flex align-center text-h6">
            <div class="brand d-flex flex-row align-center">
              <div class="brand-logo">
                <v-icon :style="'color: ' + logo.color">{{ logo.icon }}</v-icon>
              </div>
              <div class="app_brand d-flex flex-column text-left">
                <div class="brand_main" style="color: var(--v-primary-base)">
                  {{ appTitle }}
                </div>
                <div
                  class="brand_secondary"
                  style="color: var(--v-secondary-base)"
                >
                  {{ appDescription }}
                </div>
              </div>
            </div>

            <div class="lightswitch">
              <v-checkbox
                class="pt-3"
                color="secondary"
                v-model="$vuetify.theme.dark"
                off-icon="mdi-theme-light-dark"
                on-icon="mdi-theme-light-dark"
              ></v-checkbox>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Items -->
      <v-list class="head-nav">
        <v-list-item
          v-bind:key="item.path"
          v-for="item in getHeadMenuItems"
          link
          @click="
            item.reload ? hrefRefresh(item.path) : $router.push(item.path)
          "
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-if="item.status" class="status-item">
              <span>{{ item.text }}</span>

              <v-icon :color="item.status.color">
                {{ getItemStatusIcon(item.path) }}
              </v-icon>
            </v-list-item-title>

            <v-list-item-title v-else>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list v-if="getBasicMenuItems.length > 0" class="head-nav">
        <v-subheader>Tasks</v-subheader>
        <v-list-item
          v-bind:key="item.path"
          v-for="item in getBasicMenuItems"
          link
          @click="
            item.reload ? hrefRefresh(item.path) : $router.push(item.path)
          "
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="account_overview d-flex flex-row align-center">
          <div class="d-flex flex-row align-center">
            <div class="profile_picture">
              <v-avatar size="46">JS</v-avatar>
            </div>
            <div class="d-flex flex-column">
              <span class="account_name">{{ getSession.username }}</span>
              <span class="account_email">{{ userEmail }}</span>
            </div>
          </div>
          <v-menu
            transition="scale-transition"
            close-on-content-click
            offset-x
            offset-y
            top
            close-on-click
          >
            <template v-slot:activator="{ on }">
              <v-icon @click="on.click" style="margin-left: 25px"
                >mdi-dots-vertical</v-icon
              >
            </template>
            <v-list>
              <v-list-item @click="$router.push('settings')" link>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout()" link>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar color="" class="hidden-md-and-up">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "Navigation",

  props: {
    logo: Object,
    menu_items: Array,
    appTitle: String,
    appDescription: String,
  },
  mounted() {
    var token = localStorage.token;
    this.auth = false;
    if (token) {
      var user = Vue.prototype.$jwt(token);
      this.userEmail = user.email;
      if (!user) this.auth = false;
      this.auth = true;
      if (Math.floor(Date.now() / 1000) > user.exp) {
        this.auth = false;
        localStorage.clear();
      }
    }
  },

  computed: {
    getSession() {
      try {
        var session = JSON.parse(localStorage.session);
        if (session.toverify) throw "err";

        return session;
      } catch (err) {
        this.logout();
        return {};
      }
    },

    getHeadMenuItems() {
      return this.menu_items.filter((item) => item.head == true);
    },

    getBasicMenuItems() {
      return this.menu_items.filter((item) => !item.head);
    },
  },

  methods: {
    logout() {
      delete localStorage.token;
      delete localStorage.session;
      window.location.href = "../auth";
    },

    getItemStatusIcon(path) {
      const iem = this.menu_items.find((item) => item.path == path);
      if (!iem) return;

      if (iem.status) {
        return iem.status.icon;
      }

      return null;
    },
    hrefRefresh(path) {
      window.location.href = path;
    },

    updateMenuItemStatus(path, status) {
      //status = {icon, color}
      var idx = this.menu_items.findIndex((item) => item.path == path);
      if (idx == -1) return;
      this.menu_items[idx].status = status;

      console.log(this.menu_items[idx]);
    },
  },

  data() {
    return {
      accountOverviewMenu: false,
      drawer: null,
      userEmail: null,
    };
  },
};
</script>

<style lang="scss">
.v-toolbar {
  background: var(--v-bg-base) !important;
}

.lightswitch {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.account_overview {
  padding: 20px 35px;

  .profile_picture {
    margin-right: 15px;

    .v-avatar {
      color: var(--v-secondary-base);
      background: var(--v-avatar_bg-base) !important;
    }
  }

  .account_name,
  .account_email {
    font-family: "Poppins";
  }

  .account_name {
    font-size: 16px;
    color: var(--v-primary-base);
  }

  .account_email {
    font-size: 12px;
    color: var(--v-secondary-base);
  }
}

.v-subheader {
  font-family: "Poppins";
  color: var(--v-section_font_color-base) !important;
  font-weight: 800 !important;
}

.status-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    flex-grow: 1;
  }
}

.v-navigation-drawer {
  background: var(--v-bg-base) !important;
}

.head-nav {
  padding: 20px !important;

  .v-list-item__icon {
    margin-right: 16px !important;

    i {
      color: var(--v-secondary-base);
    }
  }

  .v-list-item__content {
    font-family: "Poppins";
    font-weight: 600;
    color: var(--v-secondary-base);
  }
}

.brand {
  width: 220px;
  padding: 15px;

  .app_brand {
    .brand_main {
      font-family: "Roboto";
      font-weight: 800;
      font-size: 18px;
    }

    .brand_secondary {
      font-family: "Ubuntu";
      font-size: 13px;
      letter-spacing: 0;
    }
  }

  .brand-logo {
    margin-right: 20px;
    i {
      font-size: 39px !important;
    }
  }
}
</style>
