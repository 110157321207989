export const dark =  {
    bg: "#1A1D1F",
    primary: '#ffffff',
    secondary: '#dddddd',

    searchEngine_select_menu_bgColor: "#172538",
    searchEngine_select_menu_color: "#ffffff",
    searchEngine_input_button_bgColor: "#4d73ff",
    searchEngine_input_button_color: "#ffffff",
    searchEngine_input_bgColor: "#1b2e4b",
    searchEngine_input_color: "#ffffff",
    
    result_bgColor: "#1e2530",

    support_cards_bg: "#262626",
    border: "#3e3e3e",
    font_color_bg: "#000000",
    avatar_bg: "#404040",
    section_font_color: "#707070",
  }