export const light = {
    bg: "#FFFFFF",
    primary: "#000000",
    secondary: "#6c6c6c",

    searchEngine_select_menu_bgColor: "#bfbfbf",
    searchEngine_select_menu_color: "#000000",
    searchEngine_input_button_bgColor: "#505050",
    searchEngine_input_button_color: "#ffffff",
    searchEngine_input_bgColor: "#bfbfbf",
    searchEngine_input_color: "#000000",

    result_bgColor: "#e7e7e7",

    support_cards_bg: "#f9f9f9",
    border: "#d3d3d3",
    font_color_bg: "#6c6c6c",
    avatar_bg: "#d3d3d3",
    section_font_color: "#686868",

}